<template>
  <LargeLayout>
    <div class="container">
      <div class="row body">
        <div class="col-12 login">
          <div class="card login-card">
            <div class="body login-card__body">
              <h1 class="text-left text-175rem">
                {{ $t("login.header") }} What's Up Danang
              </h1>
              <p class="text-semi-muted">
                {{ $t("login.text") }}
              </p>
              <b-toast id="my-toast" variant="warning" solid>
                This is the content of the toast. It is short and to the point.
              </b-toast>
              <b-form @submit.prevent="onSubmit">
                <b-form-group>
                  <b-form-input
                    v-model="email"
                    type="email"
                    :placeholder="$t('login.email')"
                    required
                  />
                  <b-form-input
                    v-model="password"
                    type="password"
                    :placeholder="$t('login.password')"
                    required
                  />
                  <router-link
                    :to="{ path: './forgot-password' }"
                    class="special text-decoration-underline"
                  >
                    <span class="forgot-password">{{
                      $t("login.Forgot password")
                    }}</span>
                  </router-link>

                  <b-button type="submit" variant="success" class="btn-signin">
                    {{ $t("login.Sign in") }}
                  </b-button>

                  <p class="text-center mb-0">
                    {{ $t("login.New") }}
                    <br/>
                    <router-link
                      :to="{ path: './register' }"
                      class="create-account"
                    >
                      {{ $t("login.Create account") }}
                    </router-link>
                  </p>
                </b-form-group>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </LargeLayout>
</template>

<script>
import LargeLayout from "../../../layout/main/LargeLayout.vue";
import { AUTH_REQUEST } from "../../../store/constants";
export default {
  name: "login",
  components: {
    LargeLayout,
  },
  data() {
    return {
      email: "",
      password: "",
      message: {
        login_success: {
          type: "success",
          title: this.$t("login.success_title"),
          text: this.$t("login.success_msg"),
        },
        login_fail: {
          type: "danger",
          title: this.$t("login.success_title"),
          text: this.$t("login.error_msg"),
        },
      },
    };
  },
  methods: {
    makeToast(option) {
      this.$bvToast.toast(this.message[option].text, {
        title: this.message[option].title,
        variant: this.message[option].type,
        solid: true,
        toaster: "b-toaster-top-center",
        autoHideDelay: 3000,
      });
    },
    onSubmit() {
      const data = {
        email: this.email,
        password: this.password,
      };
      this.$store
        .dispatch(AUTH_REQUEST, { data: data })
        .then(() => {
          this.$router.push("/");
          setTimeout(() => {
            this.makeToast("login_success");
          }, 500);
        })
        .catch(() => {
          this.makeToast("login_fail");
        });
    },
  },
};
</script>

<style lang="scss">
.login .form-group div {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.login {
  .card {
    border: 3px solid var(--success);
    background-color: var(--bg);
  }
  .login-card {
    width: 100%;
    margin: auto;
    margin-top: 30px;
    .login-card__body {
      padding: 20px;
      .btn-signin {
        width: 50%;
        margin: 0 auto;
        box-shadow: inset 3px 6px 6px -3px #d2ffe6,
          inset -3px -7px 6px -3px #00843b;
        padding: 12px 20px !important;
        text-shadow: -1px -1px 1px #4c4c4c;
      }
      .forgot-password,
      .create-account {
        text-decoration: underline !important;
      }
    }
  }
}
</style>
